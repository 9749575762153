import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router-dom";
function ResetAuth() {
  const toast = useToast();
  const navigate = useNavigate();
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  useEffect(() => {
    console.log("JWT Error, removing Auth");
    window.sessionStorage.removeItem("jwt");
    toast({
      title: "Authentication Expired.",
      description: "User authentication has expired, please log in again.",
      status: "error",
      duration: 6000,
      isClosable: true,
    });
    setAuth(null);
    setUser(null);
    navigate("/");
  }, [toast, navigate, auth, user, setAuth, setUser]);
  return <div></div>;
}
export default ResetAuth;
