import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Input,
  VStack,
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { WarningIcon } from "@chakra-ui/icons";
function Login() {
  const navigate = useNavigate();
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClick = () => setShow(!show);
  const logIn = async () => {
    const body = {
      email: email,
      password: password,
    };
    console.log(body);
    await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(response);
          setIsLoading(false);
          setError(true);
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setUser(data.user);
          setAuth(data.access_token);
          window.sessionStorage.setItem("jwt", data.access_token);
          setIsLoading(false);
          navigate("/Wishes");
        });
      })
      .catch((e) => console.error("Error Logging In: ", e));
  };
  return (
    <Card width="30vw" mt={"7vh"} alignSelf={"center"}>
      <CardHeader>
        <Heading size="md"> Welcome Back! </Heading>
      </CardHeader>
      <CardBody shadow={"md"}>
        <VStack>
          {error && (
            <Box
              padding="2"
              id="error-login"
              height={"3rem"}
              bg="red"
              color={"white"}
            >
              <div>
                {" "}
                <Icon mx="1" as={WarningIcon} />
                Error: Username or password invalid.
              </div>
            </Box>
          )}
          <Box>
            <Input
              pr="1.5rem"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box>
            <InputGroup>
              <Input
                pr="1.5rem"
                type={show ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box mt="2">
            <Button
              colorScheme="red"
              isLoading={isLoading}
              isDisabled={!email || !password}
              onClick={() => {
                setIsLoading(true);
                logIn();
              }}
            >
              Log in
            </Button>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
}
export default Login;
