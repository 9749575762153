import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayWish from "./DisplayWish";
import { Center } from "@chakra-ui/react";
function LinkWish() {
  const [recipient, setRecipient] = useState(null);
  const navigate = useNavigate();
  const { parentId, id } = useParams();
  useEffect(() => {
    async function getData(setRecipient) {
      await fetch(`${process.env.REACT_APP_API_URL}/${parentId}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Origin: process.env.REACT_APP_ORIGIN,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            //console.log(response)
            navigate("/404");
            return null;
          }
          response.json().then((data) => {
            console.log(data);
            setRecipient(data.recipient);
            return data;
          });
        })
        .catch((e) => console.error("Error Logging In: ", e));
    }
    console.log(parentId, id);

    getData(setRecipient);
  }, [parentId, id, navigate]);
  return <Center>{recipient && <DisplayWish recipient={recipient} />}</Center>;
}

export default LinkWish;
