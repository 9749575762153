import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  VStack,
  Box,
  Button,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router-dom";
export const modes = {
  FATHER: 1,
  RECIPIENT: 0,
  HISTORICAL: 2,
};
function DisplayWish({ recipient, completeWish, mode }) {
  const toast = useToast();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  console.log(recipient);
  const handleSubmit = async () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/recipient/complete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
        Authorization: auth,
      },
      body: JSON.stringify(recipient),
    })
      .then((response) => {
        if (response.status === 401) {
          setIsLoading(false);
          navigate("/reset");
        }
        if (response.status !== 200) {
          setIsLoading(false);
          console.log(response);
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setIsLoading(false);
          completeWish();
          toast({
            title: "Wish completed.",
            description:
              mode === mode.FATHER
                ? "Congrats on your wish fulfillment! You will receive a confirmation email shortly."
                : "Thank you for completing your wish! You will receive a confirmation email shortly.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        });
      })
      .catch((e) => console.error("Error Completing Wish: ", e));
  };
  return (
    <Card width="25vw" alignSelf={"center"}>
      <CardHeader paddingBottom={0}>
        <Heading size="md">
          {" "}
          {recipient.name}'s Wish: {recipient.wishId.wishTitle}
        </Heading>
        <Text fontSize={"sm"}>
          Status:{" "}
          {recipient.wishId.wishStatus === "0" ? "In Progress" : "Complete"}
        </Text>
      </CardHeader>
      <CardBody paddingTop={1} shadow={"md"}>
        <VStack>
          {error && (
            <Box
              padding="2"
              id="error-login"
              height={"3rem"}
              bg="red"
              color={"white"}
            >
              <div>
                {" "}
                <Icon mx="1" as={WarningIcon} />
                Error: Username or password invalid.
              </div>
            </Box>
          )}
          <Box>
            <Text fontSize="lg"> {recipient.wishId.wishDescription}</Text>
          </Box>
          <Box>
            <Text fontSize={"sm"}>
              Deadline: {recipient.wishId.completionDate}
            </Text>
          </Box>
          <Box mt="2">
            {mode === modes.HISTORICAL ? (
              <Text fontSize={"sm"}>
                Wish Completed {recipient.wishId.lastReminderDate}
              </Text>
            ) : (
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Complete wish!
              </Button>
            )}
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
}
export default DisplayWish;
