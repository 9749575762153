import {
  Heading,
  Grid,
  GridItem,
  Box,
  Center,
  Button,
  Input,
  useToast,
  ButtonGroup,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import DisplayWish from "./DisplayWish";
import { modes } from "./DisplayWish";
import ResetAuth from "../Util/ResetAuth";
function Wishes() {
  const navigate = useNavigate();
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const toast = useToast();
  useEffect(() => {
    setIsLoading(true);
    var decoded;
    console.log("Auth: " + auth);
    if (!auth) {
      const token = window.sessionStorage.getItem("jwt");
      console.log(token);
      if (!token) {
        navigate("/reset");
        return;
      }
      decoded = token;
    } else {
      decoded = auth;
    }
    console.log(decoded);
    fetch(`${process.env.REACT_APP_API_URL}/recipient/${user.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
        Authorization: decoded,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/reset");
          return;
        }
        if (response.status !== 200) {
          console.log(response);
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setRecipients(data.recipients);
        });
      })
      .catch((e) => console.error("Error Adding Recipient: ", e));
    setIsLoading(false);
  }, [user, setUser, auth, setAuth, navigate, name, toast]);
  const errorToast = () => {
    toast({
      title: "Recipient Creation Failed",
      status: "error",
      description:
        "There was an error creating the recipient, double check inputted fields.",
      duration: 4000,
      isClosable: true,
    });
  };
  const addRecipient = async () => {
    const body = {
      parentId: user.id,
      name: name,
      email: email,
      key: recipients.length,
    };
    if (!name || !email) {
      errorToast();
      return;
    }
    await fetch(`${process.env.REACT_APP_API_URL}/recipient`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
        Authorization: auth,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/reset");
        }
        if (response.status !== 200) {
          console.log(response);
          errorToast();
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setRecipients([...recipients, data.recipient]);
          toast({
            title: "Recipient Created",
            status: "success",
            description: { name } + " successfully added as a recipient.",
            duration: 4000,
            isClosable: true,
          });
        });
      })
      .catch((e) => console.error("Error Adding Recipient: ", e));
  };
  return (
    <div>
      <Heading mb="5"> Manage Wishes</Heading>
      <Grid templateColumns="repeat(10,1fr)" gap="4">
        <GridItem rowSpan={recipients.length} colSpan={2}>
          <Box width="fill-available">
            {show && (
              <div>
                <Input
                  placeholder="Name"
                  backgroundColor="white"
                  onInput={(e) => setName(e.target.value)}
                />
                <Input
                  placeholder="Email"
                  backgroundColor="white"
                  onInput={(e) => setEmail(e.target.value)}
                />
              </div>
            )}
            <ButtonGroup justifyContent={"space-evenly"}>
              {show && (
                <Button colorScheme="blue" onClick={() => setShow(!show)}>
                  Cancel
                </Button>
              )}
              <Button
                magin="0"
                colorScheme="red"
                isLoading={isLoading}
                onClick={() => {
                  if (show) {
                    addRecipient();
                  }
                  setShow(!show);
                }}
              >
                Add Recipient
              </Button>
            </ButtonGroup>
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          {recipients
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .filter((i, index) => index <= Math.floor(recipients.length / 2))
            .map((e) => {
              return <HandleWish key={e.id} e={e} />;
            })}
        </GridItem>
        <GridItem colSpan={4}>
          {recipients
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .filter((i, index) => index > Math.floor(recipients.length / 2))
            .map((e) => {
              return <HandleWish key={e.id} e={e} />;
            })}
        </GridItem>
      </Grid>
    </div>
  );
}
function HandleWish({ e }) {
  const navigate = useNavigate();
  const [number, setNumber] = useState(0);
  const [wishTitle, setWishTitle] = useState(0);
  const [wishDescription, setWishDescription] = useState(0);
  const [wishDate, setWishDate] = useState(0);
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  const toast = useToast();
  const [wish, setWish] = useState(null);
  useEffect(() => {
    setNumber(e.id);
    setWish(e.wishId);
  }, [e, e.id]);
  const handleSubmit = () => {
    const wish = {
      wishTitle: wishTitle,
      wishDescription: wishDescription,
      completionDate: wishDate,
      wishStatus: "0", // 0 IN_PROGRESS, 1 COMPLETE
    };
    e.wishId = wish;
    setWish(wish);
    console.log(e);
    fetch(`${process.env.REACT_APP_API_URL}/recipient/wish`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
        Authorization: auth,
      },
      body: JSON.stringify(e),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/reset");
        }
        if (response.status !== 200) {
          console.log(response);
          toast({
            title: "Wish Creation Failed",
            status: "error",
            description:
              "There was an error creating the wish, double check inputted fields.",
            duration: 4000,
            isClosable: true,
          });
          return;
        }
        response.json().then((data) => {
          console.log(data);
        });
      })
      .catch((e) => console.error("Error Adding Recipient: ", e));
  };
  return (
    <Box backgroundColor={"realtime.100"} mb="2" height={"30vh"}>
      <Button
        id={number}
        width="fill-available"
        height={"4vh"}
        _hover={{ bg: "#e0403a" }}
        backgroundColor={"#ff7a75"}
        onClick={() => {
          navigate("/historicalWishes/" + number + "/" + e.name);
        }}
      >
        <StarIcon colorscheme="red" aria-label="Wish Receiver" w={"4vw"} />
        <Box
          as="span"
          flex={"1"}
          textAlign={"left"}
          fontWeight="semibold"
          letterSpacing="wide"
          ml="2"
        >
          {e.name}
        </Box>
      </Button>
      <Box id={number + "click"} m="4" display={"block"}>
        {wish && (
          <Center>
            <DisplayWish
              recipient={e}
              completeWish={() => setWish(null)}
              mode={modes.FATHER}
            />
          </Center>
        )}
        {!wish && (
          <Box>
            <Input
              onInput={(e) => setWishTitle(e.target.value)}
              mb="3"
              backgroundColor={"#FFF"}
              placeholder="Wish Title"
            ></Input>
            <Input
              onInput={(e) => setWishDescription(e.target.value)}
              mb="3"
              height="7vh"
              textAlign={"top"}
              backgroundColor={"#FFF"}
              placeholder="Wish Description..."
            ></Input>
            <Input
              onInput={(e) => setWishDate(e.target.value)}
              mb="3"
              backgroundColor={"#FFF"}
              placeholder="Select Completion Date"
              type="date"
            />
            <Button onClick={handleSubmit} colorScheme="red">
              Create Wish
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default Wishes;
