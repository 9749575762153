import "./App.css";
import { useEffect, createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import Navbar from "./Util/Navbar";

export const UserContext = createContext(null);
//
//  </UserContext.provider>
function App() {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const token = window.sessionStorage.getItem("jwt");
    if (token) {
      const decoded = jwt_decode(token);
      console.log(decoded);
      setAuth(token);
      setUser({
        name: decoded.name,
        email: "test@gmail.com",
        id: decoded.userId,
      });
    }
    setLoading(false);
  }, []);
  return (
    <div className="App">
      <UserContext.Provider
        value={{ user: user, setUser: setUser, auth: auth, setAuth: setAuth }}
      >
        <Navbar loading={loading} />
      </UserContext.Provider>
    </div>
  );
}
export default App;
