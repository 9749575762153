import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { Center } from "@chakra-ui/react";
import DisplayWish, { modes } from "./DisplayWish";
function HistoricalWish() {
  const { id, name } = useParams();
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  const navigate = useNavigate();
  const [historicalWishes, setHistoricalWishes] = useState([]);
  const transformWish = (historicalWish) => {
    const recipient = {
      name: name,
      wishId: {
        wishStatus: historicalWish.wishStatus,
        wishTitle: historicalWish.wishTitle,
        wishDescription: historicalWish.wishDescription,
        completionDate: historicalWish.completionDate,
        lastReminderDate: historicalWish.lastReminderDate,
      },
    };
    return recipient;
  };
  useEffect(() => {
    async function getData(setRecipient) {
      await fetch(
        `${process.env.REACT_APP_API_URL}/recipient/historicalWish/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Origin: process.env.REACT_APP_ORIGIN,
            Authorization: auth,
          },
        },
      )
        .then((response) => {
          if (response.status === 401) {
            navigate("/reset");
          }
          if (response.status !== 200) {
            //console.log(response)
            return null;
          }
          response.json().then((data) => {
            console.log(data);
            setRecipient(data.historicalWishes);
            return data;
          });
        })
        .catch((e) => console.error("Error Logging In: ", e));
    }
    console.log(id);

    getData(setHistoricalWishes);
  }, [id, auth, navigate]);
  return (
    <Center>
      {historicalWishes?.map((e) => (
        <DisplayWish
          recipient={transformWish(e)}
          mode={modes.HISTORICAL}
          completeWish={() => {}}
        />
      ))}
    </Center>
  );
}
export default HistoricalWish;
