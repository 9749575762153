import { Stack } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import Header from "./Header";
import Signup from "../pages/Signup";
import Welcome from "../pages/Welcome";
import Login from "../pages/Login";
import Wishes from "../pages/Wishes";
import { useContext } from "react";
import { UserContext } from "../App";
import LinkWish from "../pages/LinkWish";
import ResetAuth from "./ResetAuth.js";
import HistoricalWish from "../pages/HistoricalWish.js";
function Navbar() {
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  return (
    <BrowserRouter>
      <Header isLoggedIn={auth} />
      <Stack display={["none", "none", "block"]}>
        <Stack pt="1rem" as="main" maxWidth="8xl" marginInline="auto">
          {auth !== null ? (
            <Routes>
              <Route path="/Wishes" element={<Wishes />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/wish/:parentId/:id" element={<LinkWish />} />
              <Route path="/reset" element={<ResetAuth />} />
              <Route
                path="/historicalWishes/:id/:name"
                element={<HistoricalWish />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route path="/Auth/Signup" element={<Signup />} />
              <Route path="/Auth/LogIn" element={<Login />} />
              <Route path="/" element={<Welcome />} />
              <Route path="/wish/:parentId/:id" element={<LinkWish />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          )}
        </Stack>
      </Stack>
    </BrowserRouter>
  );
}
export default Navbar;
