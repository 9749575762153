import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Input,
  VStack,
  Box,
  Button,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";

function Signup() {
  const { user, setUser, auth, setAuth } = useContext(UserContext);
  const navigate = useNavigate();
  const [submit, setSubmitable] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClick = () => setShow(!show);
  const regex = new RegExp("(^[w][w_.+-]+){1,}@[w_.-]+.[w]{2,}$");
  const updateFirstName = (value) => {
    if (!value) {
      setFirstName(value);
      setFirstNameError(true);
      return;
    }
    setFirstName(value);
    setFirstNameError(false);
  };
  const updateLastName = (value) => {
    if (!value) {
      setLastName(value);
      setLastNameError(true);
      return;
    }
    setLastName(value);
    setLastNameError(false);
  };
  const updateEmail = (value) => {
    if (!value && regex.test(value)) {
      setEmail(value);
      setEmailError(true);
      return;
    }
    setEmail(value);
    setEmailError(false);
  };
  const updatePassword = (value) => {
    if (!value) {
      setPassword(value);
      setPasswordError(true);
      return;
    }
    setPassword(value);
    setPasswordError(false);
  };
  const signUp = async () => {
    if (passwordError || firstNameError || lastNameError || emailError) {
      return;
    }
    const body = {
      name: firstName + " " + lastName,
      email: email,
      password: password,
    };
    setIsLoading(true);
    console.log(body);
    fetch(`${process.env.REACT_APP_API_URL}/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ORIGIN,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(response);
          setIsLoading(false);
          //setError(true)
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setUser(data.user);
          setAuth(data.access_token);
          window.sessionStorage.setItem("jwt", data.access_token);
          setIsLoading(false);
          navigate("/Wishes");
        });
      })
      .catch((e) => console.error("Error Signing Up: ", e));
  };
  return (
    <Card width="30vw" mt={"7vh"} alignSelf={"center"}>
      <CardHeader>
        <Heading size="md"> Send Your Wishes Today! </Heading>
      </CardHeader>
      <CardBody>
        <VStack>
          <Box width="20vw" height="4rem">
            <FormControl isInvalid={firstNameError} isRequired>
              <Input
                placeholder="First Name"
                onChange={(e) => updateFirstName(e.target.value.trim())}
              />
              <FormErrorMessage>First Name is required.</FormErrorMessage>
            </FormControl>
          </Box>
          <Box width="20vw" height="4rem">
            <FormControl isInvalid={lastNameError} isRequired>
              <Input
                placeholder="Last Name"
                isInvalid={lastNameError}
                onChange={(e) => updateLastName(e.target.value.trim())}
              />
              <FormErrorMessage>Last Name is required.</FormErrorMessage>
            </FormControl>
          </Box>
          <Box width="20vw" height="4rem">
            <FormControl isInvalid={emailError} isRequired>
              <Input
                placeholder="Email"
                isInvalid={emailError}
                onChange={(e) => updateEmail(e.target.value.trim())}
              />
              <FormErrorMessage>Email is required.</FormErrorMessage>
            </FormControl>
          </Box>
          <Box width="20vw" height="4rem">
            <FormControl isInvalid={passwordError} isRequired>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  isInvalid={passwordError}
                  placeholder="Password"
                  onChange={(e) => updatePassword(e.target.value.trim())}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Password is required.</FormErrorMessage>
            </FormControl>
          </Box>
          <Box mt="2" width="20vw">
            <Button
              width="20vw"
              isDisabled={
                !email ||
                !firstName ||
                !lastName ||
                !password ||
                passwordError ||
                firstNameError ||
                lastNameError ||
                emailError
              }
              isLoading={isLoading}
              colorScheme="red"
              onClick={signUp}
            >
              Sign up
            </Button>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
}
export default Signup;
