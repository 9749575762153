// theme.js

// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react";

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#FBF4F5",
        color: "#1A090B",
      },
    },
  },
  colors: {
    realtime: {
      100: "#FBF4F5",
      200: "#A93D49",
      300: "#ECCBCE",
      400: "#AC3E4B",
    },
  },
});

export default theme;
