import { Box, HStack, Heading } from "@chakra-ui/react";
import React from "react";
import Signup from "./Signup";

function Welcome() {
  return (
    <div>
      <HStack ml="10vw" spacing={"20vw"}>
        <Box>
          <Heading>Welcome to Father's Wish</Heading>
        </Box>
        <Box width="40vw">
          <Signup />
        </Box>
      </HStack>
      <Heading mt="20">
        Home of Parental Guidance and Paternal Connection.
      </Heading>
    </div>
  );
}
export default Welcome;
