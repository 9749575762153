import React, { useContext } from "react";
import { Box, Button, Heading, HStack, IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { StarIcon } from "@chakra-ui/icons";
import { UserContext } from "../App";
const Header = (props) => {
  const { auth } = useContext(UserContext);
  return (
    <Box
      as="header"
      borderWidth="1px"
      background="chakra-body-bg"
      py="1rem"
      position="sticky"
      zIndex={999}
      top={0}
      left={0}
      right={0}
    >
      <HStack px="1rem" maxWidth="8xl" marginInline="auto">
        <Link to={auth ? "/Wishes" : "/"}>
          <HStack>
            <Box
              borderRadius={20}
              w={9}
              h={9}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <img src="/icons8-star-pastel-96.ico" alt="image" />
            </Box>
            <Heading fontWeight="bold" fontSize="3xl">
              Father's Wish
            </Heading>
          </HStack>
        </Link>
        <HStack
          display={["none", "none", "flex"]}
          flexGrow={1}
          alignItems="center"
          justifyContent="end"
        >
          {auth !== null ? (
            <>
              <Link to="/Wishes">
                <IconButton
                  variant="ghost"
                  colorScheme="yellow"
                  icon={<StarIcon />}
                  aria-label="Wish Page"
                />
              </Link>
            </>
          ) : (
            <>
              <Link to="/Auth/Login">
                <Button colorScheme="red" variant="outline">
                  Login
                </Button>
              </Link>
              <Link to="/Auth/Signup">
                <Button colorScheme="red">Signup</Button>
              </Link>
            </>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default Header;
